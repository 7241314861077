import Autocomplete from './autocomplete';
import CarParkList from './car_park_list';
import CarParkPopup from './car_park_popup';
import RegionSelect from './region_select';

export default class MapManager {

  static async init() {
    const libMaps    = await google.maps.importLibrary("maps");
    const libMarkers = await google.maps.importLibrary("marker");
    self.GMap       = libMaps.Map;
    self.GMarker    = libMarkers.AdvancedMarkerElement;

    self.searchMarker = new self.GMarker();

    self.carParkMarkers = [];

    self.canvas = document.querySelector("#map");
    if ( self.canvas ) {
      self.carWashView = (self.canvas.dataset.carWashView === "true");

      self.initMap();
      calculateMapHeight();

      RegionSelect.init();
      CarParkList.init();
      CarParkPopup.init();
      Autocomplete.init();

      self.bindMarkers();
      self.handlePreselected();
    }
  }

  static handlePreselected() {
    const preSelectedRegionId = self.canvas.dataset.preSelectedRegionId;

    if ( preSelectedRegionId ) {
      RegionSelect.setRegion(preSelectedRegionId);
    }

    // @Todo If preSelectedRegionId is set, we might want the preSelectedCarPark
    // to also be selected in the dropdown. For this to happen, the car park selection
    // must happen after loadCarParkListByRegion concludes.

    // @Note Pre-open a specific car park when preselectedCarPark is set
    const preSelectedCarParkId = self.canvas.dataset.preSelectedCarPark;
    if ( preSelectedCarParkId ) {
      const marker = self.getMarkerFromCarPark(preSelectedCarParkId);
      if ( marker ) {
        google.maps.event.trigger(marker, "click");
      }
    }

  }

  static bindMarkers() {
    document.addEventListener("mouseover", (e) => {
      const markerIcon = e.target.closest(".marker-icon");
      if ( markerIcon ) {
        return;
      }
    });

    for (var i = 0; i < self.carParkMarkers.length; i++) {
      const marker = self.carParkMarkers[i];

      marker.addListener("click", () => {
        CarParkList.setActive(marker.carParkId);
        CarParkPopup.load(marker.carParkId);
      });

      // @Todo The new "advanced" markers google wants us to use
      // don't seem to have a mouseover/mouseout event. So we attach
      // them on both icon images. The beta channel seems to allow us
      // to use addEventListener which may mean that it'll be possible
      // to simplify this?
      marker.activeIcon.addEventListener("mouseover", () => {
        CarParkList.hoverOnCarPark(marker.carParkId);
      })

      marker.inactiveIcon.addEventListener("mouseover", () => {
        CarParkList.hoverOnCarPark(marker.carParkId);
      })

      marker.activeIcon.addEventListener("mouseout", () => {
        CarParkList.exitHoverOnCarPark(marker.carParkId);
      })

      marker.inactiveIcon.addEventListener("mouseout", () => {
        CarParkList.exitHoverOnCarPark(marker.carParkId);
      })
    }
  }

  static setSearchMarker(lat, lng, visibility) {
    self.searchMarker.position = { lat: lat, lng: lng};
    self.searchMarker.map = visibility ? self.homeMap : null;
  }

  static setBounce(carParkId, toggle) {
    let marker = self.getMarkerFromCarPark(carParkId);

    if ( marker ) {
      if ( toggle ) {
        marker.activeIcon.classList.add("bounce");
        marker.inactiveIcon.classList.add("bounce");
      } else {
        marker.activeIcon.classList.remove("bounce");
        marker.inactiveIcon.classList.remove("bounce");
      }
    }
  }

  // @Note Finds the marker that corresponds to the given
  //      car park - returns null if not found.
  static getMarkerFromCarPark(carParkId) {
    var result = null;
    for (var i = 0; i < self.carParkMarkers.length; i++) {
      const marker = self.carParkMarkers[i];
      if ( marker.carParkId == carParkId ) {
        result = marker;
        break;
      }
    }

    return result;
  }

  static filterMarkersByRegion(regionId) {
    if ( regionId === "all_regions" ) { regionId = null; }
    CarParkPopup.hide();

    for (var i = 0; i < self.carParkMarkers.length; i++) {
      const marker = self.carParkMarkers[i];
      const isVisible = ( regionId == null ) || ( marker.regionId === regionId );
      marker.map = isVisible ? self.homeMap : null;
    }
  }

  static filterMarkersByIds(carParkIds) {
    CarParkPopup.hide();

    for (var i = 0; i < self.carParkMarkers.length; i++) {
      const marker = self.carParkMarkers[i];
      const isVisible = ( carParkIds.includes(marker.carParkId) );
      marker.map = isVisible ? self.homeMap : null;
    }
  }

  // @Note Focuses the given car park marker by setting all the other
  //      marker icons to grey. If the given car park is null then
  //      all the markers will return to the normal yellow.
  static focusMarker(carParkId) {
    for (var i = 0; i < self.carParkMarkers.length; i++) {
      const marker = self.carParkMarkers[i];

      if ( carParkId && marker.carParkId != carParkId ) {
        marker.content = marker.inactiveIcon;
      } else {
        marker.content = marker.activeIcon;

        if ( carParkId ) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(marker.position);
          self.homeMap.fitBounds(bounds);

          // @Note Ensure we didn't zoom too much
          if (self.homeMap.getZoom() > 17) {
            self.homeMap.setZoom(17);
          }
        }

      }
    }
  }

  // @Note recenters the map to the visible markers, used after changing
  //      marker visibility.
  static centerMarkers() {
    const markers = self.carParkMarkers.concat(self.searchMarker);
    const bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < markers.length; i++) {
      const marker = markers[i];
      if (marker.map) {
        bounds.extend(marker.position);
      }
    }
    self.homeMap.fitBounds(bounds);

    // @Note Ensure we didn't zoom too much
    if (self.homeMap.getZoom() > 17) {
      self.homeMap.setZoom(17);
    }
  }

  static addControlButton(id, initialState, onClick) {
    const template = document.getElementById(id);
    var btnHTML = id;
    if ( template ) { btnHTML = template.innerHTML; }

    const container = document.createElement("div");
    const btn = document.createElement("button");
    btn.type = "button";
    btn.classList.add("gmaps-button");
    btn.innerHTML = btnHTML;

    self.controlStates ||= {};
    self.controlStates[id] = initialState;

    const setBtnToggledState = () => {
      if ( self.controlStates[id] ) {
        btn.classList.add("toggled");
      } else {
        btn.classList.remove("toggled");
      }
    }

    setBtnToggledState();
    onClick(self.controlStates[id]);
    btn.addEventListener("click", (e) => {
      self.controlStates[id] = !self.controlStates[id];
      setBtnToggledState();

      onClick(self.controlStates[id]);
    });

    self.homeMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(btn);
  }

  static initMap() {
    const center = {
      lat: parseFloat(self.canvas.dataset.centerLat),
      lng: parseFloat(self.canvas.dataset.centerLon),
    };
    const bounds = new google.maps.LatLngBounds(
      { lat: parseFloat(self.canvas.dataset.swLat), lng: parseFloat(self.canvas.dataset.swLon) },
      { lat: parseFloat(self.canvas.dataset.neLat), lng: parseFloat(self.canvas.dataset.neLon) }
    );
    bounds.extend(center);

    self.homeMap = new self.GMap(self.canvas, {
      mapId: "citywebappmapid",
      center: center,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      scaleControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      zoom: 8,
    });
    self.homeMap.fitBounds(bounds);

    {
      self.bikeRoutes = [];
      $.ajax({
        url: "/bike_routes"
      }).done(function(json) {
        for(var i = 0; i < json.length; i++) {
          const kmlFile = json[i];

          self.bikeRoutes.push(new google.maps.KmlLayer({
            url: kmlFile,
            suppressInfoWindows: true,
            preserveViewport: true,
          }));
        }
      });
      self.addControlButton("gmap-bike", false, (toggledState) => {
        for(var i = 0; i < self.bikeRoutes.length; i++) {
          self.bikeRoutes[i].setMap(toggledState ? self.homeMap : null);
        }
      })

      self.trafficLayer = new google.maps.TrafficLayer();
      self.addControlButton("gmap-traffic", false, (toggledState) => {
        self.trafficLayer.setMap(toggledState ? self.homeMap : null);
      })
    }

    for (const markerId in markerJson) {
      const markerData = markerJson[markerId];
      const marker = new self.GMarker({
        title: markerData.name,
        position: { lat: parseFloat(markerData.lat), lng: parseFloat(markerData.lon) },
        map: self.homeMap,
      });
      marker.carParkId = markerId;
      marker.regionId = markerData.region_id.toString();
      marker.isNew = markerData.is_new;
      marker.activeIcon   = self.makeMarkerIcon(marker.isNew ? self.canvas.dataset.pinNew : self.canvas.dataset.pin);
      marker.inactiveIcon = self.makeMarkerIcon(marker.isNew ? self.canvas.dataset.pinNewGray : self.canvas.dataset.pinGray);
      marker.content = marker.activeIcon;

      self.carParkMarkers.push(marker);
    }

    self.searchMarker.content = self.makeMarkerIcon(self.canvas.dataset.pinSearch, 25, 25);
  }

  static makeMarkerIcon(src, w = 55, h = 55) {
    var icon = document.createElement("img");
    icon.classList.add("marker-icon");
    icon.src = src
    icon.width = w;
    icon.height = h;
    return icon;
  }

}

const self = MapManager;
